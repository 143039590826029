import * as productTransformer from "./productTransformer"
import * as impactTransformer from "./impactTransformer"
import he from "he"
import { transformImage } from "./imageTransformer"
import { safeHeDecode } from "../../social-supermarket/util/generalUtil"
import { ProductTaxonomyType } from "@social-supermarket/social-supermarket-model"

export const fromGatsby = (gatsbyCategory: any, type: string): ProductTaxonomyType => {
  return {
    slug: gatsbyCategory.slug,
    name: safeHeDecode(gatsbyCategory.name || ""),
    title: gatsbyCategory.custom?.title,
    type: type,
    description: gatsbyCategory.custom?.categoryDescription || gatsbyCategory.description,
    oneLineDescription: gatsbyCategory.custom?.oneLineDescription,
    products: gatsbyCategory.products?.nodes
      ? productTransformer.multipleFromGatsby(gatsbyCategory.products.nodes)
      : [],
    impacts: gatsbyCategory.impacts?.nodes
      ? impactTransformer.multipleFromGatsby(gatsbyCategory.impacts?.nodes)
      : [],
    children: gatsbyCategory.wpChildren?.nodes.map(node => fromGatsby(node, type)),
    image: transformImage(
      gatsbyCategory.image || gatsbyCategory.custom?.image,
      safeHeDecode(gatsbyCategory.name || "")
    ),
  }
}

export const fromGatsbyNoProducts = (gatsbyCategory: any, type: string): ProductTaxonomyType => {
  return gatsbyCategory
    ? {
        slug: gatsbyCategory.slug,
        name: safeHeDecode(gatsbyCategory.name || ""),
        title: gatsbyCategory.custom?.title,
        uniqueSellingPoints: gatsbyCategory.custom?.uniqueSellingPoints
          ? gatsbyCategory.custom?.uniqueSellingPoints.map(u => u.description)
          : undefined,
        showGetInTouchButton: gatsbyCategory.custom?.showGetInTouchButton,
        seoDescription: gatsbyCategory.custom?.seoDescription,
        leadCapture: gatsbyCategory.custom?.leadCapture,
        type: type,
        description: gatsbyCategory.custom?.categoryDescription || gatsbyCategory.description,
        oneLineDescription: gatsbyCategory.custom?.oneLineDescription,
        impacts: gatsbyCategory.impacts?.nodes
          ? impactTransformer.multipleFromGatsby(gatsbyCategory.impacts?.nodes)
          : [],
        children: gatsbyCategory.wpChildren?.nodes.map(node => fromGatsby(node, type)),
        image: transformImage(
          gatsbyCategory.image || gatsbyCategory.custom?.image,
          safeHeDecode(gatsbyCategory.name || "")
        ),
        parent: fromGatsbyNoProducts(gatsbyCategory.wpParent?.node, type),
        initialProducts: gatsbyCategory.custom?.initialProducts,
        showFilters: gatsbyCategory.custom?.showFilters,
        subCategoriesInSidebar: !!gatsbyCategory.custom?.subcategoriesInSidebar,
        productCarouselName: gatsbyCategory.custom?.productCarouselName,
        uspFeatures: gatsbyCategory.custom?.uspFeatures?.map(f => ({
          title: f.title,
          description: f.description,
          image: transformImage(f.image, f.title),
        })),
        showCorporateEnquiry: gatsbyCategory.custom?.showCorporateEnquiry,
        hideProductBrands: gatsbyCategory.custom?.hideProductBrands,
        gatedContent: gatsbyCategory.custom?.gatedContent?.title
          ? {
              title: gatsbyCategory.custom?.gatedContent.title,
              description: gatsbyCategory.custom?.gatedContent.description,
              image: transformImage(
                gatsbyCategory.custom?.gatedContent.image,
                safeHeDecode(gatsbyCategory.custom?.gatedContent.title)
              ),
              buttonTitle: gatsbyCategory.custom?.gatedContent.buttonTitle,
              popupTitle: gatsbyCategory.custom?.gatedContent.popupTitle,
              popupDescription: gatsbyCategory.custom?.gatedContent.popupDescription,
              fileUrl: gatsbyCategory.custom?.gatedContent.fileUrl,
              eventName: gatsbyCategory.custom?.gatedContent.eventName,
              analyticsEventName: gatsbyCategory.custom?.gatedContent.analyticsEventName,
            }
          : undefined,
      }
    : null
}
