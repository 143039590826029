import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import Accordion from "../../components/accordion/Accordion"
import AccordionItem from "../../components/accordion/AccordionItem"
import AccordionHeader from "../../components/accordion/AccordionHeader"
import AccordionBody from "../../components/accordion/AccordionBody"

const Container = styled.div`
  margin: 40px auto;
  width: 100%;
`
const Message = styled.p`
  font-size: 0.9em;
  padding: 5px 10px 10px;
`

interface AccordionItemType {
  header: string
  message: string
}

interface Props {
  items: AccordionItemType[]
}

const GiftBoxesAccordion: FC<Props> = ({ items }) => (
  <Container>
    <Accordion>
      {items.map(({ header, message }: AccordionItemType) => (
        <AccordionItem>
          <AccordionHeader>{header}</AccordionHeader>
          <AccordionBody>
            <Message dangerouslySetInnerHTML={{ __html: message }} />
          </AccordionBody>
        </AccordionItem>
      ))}
    </Accordion>
  </Container>
)

export default GiftBoxesAccordion
