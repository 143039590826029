import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { multipleFromGatsby } from "../transformers/impactTransformer"
import ImpactBanner from "../../social-supermarket/components/impact/ImpactBanner"

const Container = styled.div``

interface Props {
  iconSlugs?: string[]
}

const GImpactBanner: FC<Props> = ({ iconSlugs }) => {
  const data = useStaticQuery(queryString)
  const impacts = multipleFromGatsby(data.allWpImpact.nodes)
  let renderImpacts = []
  if (iconSlugs) {
    iconSlugs.forEach(slug => {
      const impact = impacts.find(impact => impact.slug === slug)
      if (impact) {
        renderImpacts.push(impact)
      }
    })
  } else {
    renderImpacts = impacts
  }
  return <ImpactBanner impacts={renderImpacts} />
}

const queryString = graphql`
  query {
    allWpImpact {
      nodes {
        name
        slug
        icon {
          iconSvg
          plainIconSvg
        }
      }
    }
  }
`

export default GImpactBanner
