import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { bigDesktop, smallMobile, tablet } from "../../constants/screenSizes"
import { minionFont, tradeGothic } from "../../constants/fonts"

const Container = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 60px 10px;
`

const Body = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-width: 350px;
  padding: 0 15px;
  @media (max-width: ${bigDesktop}px) {
    margin-bottom: 15px;
  }
  @media (max-width: ${smallMobile}px) {
    padding: 0;
  }
`
const ExplainIcon = styled.div`
  margin-bottom: 35px;
`

const ExplainTitle = styled.p`
  padding-bottom: 22px;
  font-family: ${tradeGothic};
  font-size: 1.8rem;
  line-height: 1;
  margin: 0;
  text-align: center;
`

const ExplainDescription = styled.div`
  text-align: center;
  font-size: 1rem;
  line-height: 1.4;
`

const Title = styled.h2<{ margin: string; mobileFontSize: string; width: string }>`
  width: 100%;
  margin: auto;
  text-align: center;
  font-family: ${minionFont};
  font-size: 2.5em;
  text-transform: unset;
  margin-bottom: 40px;

  @media (max-width: ${tablet}px) {
    font-size: 2.5em;
    padding: 0 10px;
  }
`

export interface SellingPointType {
  Svg: string
  description: string
  title: string
}

interface Props {
  backgroundColor: string
  items: SellingPointType[]
  title: string
}

const ThreeSellingPoints: FC<Props> = ({ backgroundColor, items, title }) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <Title>{title}</Title>
      <Body>
        {items.map(({ Svg, title, description }) => (
          <Item key={title}>
            <ExplainIcon>
              <Svg />
            </ExplainIcon>
            <ExplainTitle>{title}</ExplainTitle>
            <ExplainDescription>{description}</ExplainDescription>
          </Item>
        ))}
      </Body>
    </Container>
  )
}

export default ThreeSellingPoints
