import * as React from "react"
import GiftBoxesLandingPage from "../social-supermarket/pages/gift-boxes-landing-page/GiftBoxesLandingPage"
import { graphql } from "gatsby"
import { multipleFromGatsby } from "../gatsby/transformers/productTransformer"
import * as taxonomyTransformer from "../gatsby/transformers/productTaxonomyTransformer"
import ImageType from "../social-supermarket/model/ImageType"

const GiftBoxes = ({ data: { allWp, allWpCollection, file } }): JSX.Element => {
  const taxonomies = allWpCollection.nodes.map(node =>
    taxonomyTransformer.fromGatsby(node, "collection")
  )
  const headerImage: ImageType = {
    gatsbyImageData: file.childImageSharp.gatsbyImageData,
    alt: "Social Enterprise Crimson King Hamper",
  }

  const productStrip1 = allWp.nodes[0].options.custom.productStrip1
  const productStrip2 = allWp.nodes[0].options.custom.productStrip2

  return (
    <GiftBoxesLandingPage
      taxonomies={taxonomies}
      headerImage={headerImage}
      productStrip1={productStrip1}
      productStrip2={productStrip2}
    />
  )
}

export default GiftBoxes

export const query = graphql`
  {
    file(relativePath: { eq: "crimson-king-cut.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, placeholder: NONE)
      }
    }
    allWpCollection(filter: { custom: { tags: { regex: "/hamper-landing-page/" } } }) {
      nodes {
        slug
        name
        description
        custom {
          oneLineDescription
          image {
            ...UrlImageFragment
          }
        }
      }
    }
    allWp {
      nodes {
        options {
          custom {
            productStrip1 {
              image {
                ...UrlImageFragment
              }
              link
            }
            productStrip2 {
              image {
                ...UrlImageFragment
              }
              link
            }
          }
        }
      }
    }
  }
`
