import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import GImage from "../../../gatsby/GImage"
import { grey, primaryLight } from "../../constants/colors"
import GLink from "../../../gatsby/GLink"
import { tablet } from "../../constants/screenSizes"
import { ProductTaxonomyType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  background-color: ${grey};
  @media (max-width: ${tablet}px) {
    margin-bottom: 40px;
  }
`
const Image = styled.div``
const Body = styled.div<{ hasDescription: boolean }>`
  min-height: ${({ hasDescription }) => (hasDescription ? "170px" : "30px")};
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: ${tablet}px) {
    min-height: ${({ hasDescription }) => (hasDescription ? "170px" : "50px")};
  }
`
const Title = styled.div`
  font-family: "TradeGothic", "Andale Mono", sans-serif;
  text-transform: uppercase;
  font-size: 1em;
`
const Description = styled.div`
  font-size: 0.75em;
  padding: 10px 0;
`
const LearnMore = styled.div`
  margin-top: auto;
  a {
    color: ${primaryLight};
    text-decoration: underline;
    font-size: 0.8em;
  }
`

interface Props {
  taxonomy: ProductTaxonomyType
  hasDescription: boolean
}

const ProductTaxonomyCard: FC<Props> = ({ taxonomy, hasDescription }) => {
  return (
    <Container>
      <Image>
        <GLink to={`/${taxonomy.type}/${taxonomy.slug}`}>
          <GImage
            image={taxonomy.image}
            objectFit={"cover"}
            style={{ height: 170, width: "100%" }}
          />
        </GLink>
      </Image>
      <Body hasDescription={hasDescription}>
        <Title>
          <GLink to={`/${taxonomy.type}/${taxonomy.slug}`}>{taxonomy.name}</GLink>
        </Title>

        {hasDescription && <Description>{taxonomy.oneLineDescription}</Description>}
        {hasDescription && (
          <LearnMore>
            <GLink to={`/${taxonomy.type}/${taxonomy.slug}`}>Learn More</GLink>
          </LearnMore>
        )}
      </Body>
    </Container>
  )
}

export default ProductTaxonomyCard
